<template>
  <div>
    <div class="title">{{ $t("fillInformation") }}</div>
    <el-form ref="form" :model="form" label-width="200px">
      <el-form-item :label="$t('Orderamount')" prop="orderMoney">
         <span>{{form.repayAmount}} {{$t('millionDollars')}}</span>
      </el-form-item>
      <el-form-item
        :label="$t('financingAmountRatio')"
        prop="rate"
        label-width="250px"
      >
        <span>{{ form.apr }}</span>
      </el-form-item>
      <el-form-item :label="$t('financingAmount')">
        <span>
          {{ form.askAmount }} {{ $t("millionDollars") }}
        </span>
      </el-form-item>
      <el-form-item
        :label="$t('repaymentMethod')"
        prop="method"
        label-width="250px"
      >
        <span>{{ $t("equalPrincipal") }}</span>
      </el-form-item>
      <el-form-item :label="$t('dateOfIssuance')" prop="payReceived">
        <span>{{ form.manufacturingStartDate }}</span>
      </el-form-item>
      <el-form-item
        :label="$t('deadlinefForFund')"
        label-width="250px"
      >
        <span>{{ form.deadlineDate }}</span>
      </el-form-item>
      <el-form-item :label="$t('promisedPaymentDateAgain')" prop="repayment">
        <span>{{ form.repayment }}</span>
      </el-form-item>
      <el-form-item
        :label="$t('borrowingCycle')"
        label-width="250px"
      >
        <span>{{ form.day }}&nbsp;{{ $t("day") }}</span>
      </el-form-item>
      <el-form-item :label="$t('financingAgent')" prop="person">
        <span>{{ form.buyer }}</span>
      </el-form-item>
      <el-form-item :label="$t('phoneNumber')" prop="phone" label-width="250px">
        <span>{{ form.description }}</span>
      </el-form-item>
      <el-form-item :label="$t('brandOwner')" prop="brandOwnerAddress">
        <span>{{ form.brandOwner }}</span>
      </el-form-item>
      <el-form-item v-if="form.proofUrl" :label="$t('uploadOrderFile')" style="width: 100%;">
        <el-link :underline="false" type="primary" :href="form.proofUrl" target="_blank">{{ form.proofUrl.split('/')[form.proofUrl.split('/').length-1] }}</el-link>
      </el-form-item>
      <el-form-item
        :label="$t('financingPurpose')"
        style="width: 1100px"
        prop="desc"
      >
        <span>{{ form.purpose }}</span>
      </el-form-item>
      <el-form-item :label="$t('receivableContract')" style="width: 100%;">
        <el-link v-if="extraData.fileUrl" :underline="false" type="primary" target="_blank" :href="extraData.fileUrl">{{ extraData.fileUrl.split("/")[extraData.fileUrl.split("/").length - 1] }}</el-link>
      </el-form-item>
    </el-form>
    <div class="title">
      {{ $t("confirmInformation") }}
      <!-- <span style="color: #81D8D0">！</span> -->
    </div>
    <el-form
      ref="formBank"
      :model="form"
      style="width: 100%;"
      label-width="200px"
    >
      <el-form-item :label="$t('bankNumber')" prop="account">
        <span>{{ extraData.account }}</span>
      </el-form-item>
      <el-form-item :label="$t('bankAccount')" prop="user" label-width="250px">
        <span>{{ extraData.user }}</span>
      </el-form-item>
      <el-form-item :label="$t('bankOfDeposit')" prop="bank">
        <span>{{ extraData.bank }}</span>
      </el-form-item>
    </el-form>
    <div style="text-align: center">
      <el-button class="submit btn-black" v-on:click="cancel">{{
        $t("btnI18n.Close")
      }}</el-button>
    </div>
  </div>
</template>
<script>
import { connect } from '../../utils/blockchain';
import { subDays, format, differenceInDays } from 'date-fns';
import { Decrypt } from "@/utils/crypto";
export default {
  data() {
    return {
      form: {},
      extraData: {}
    };
  },
  mounted() {
    this.form = Object.assign(this.form, JSON.parse(sessionStorage.getItem("repaymentDetail")));
    this.extraData = JSON.parse(this.form.extraData);
    this.extraData.fileUrl = Decrypt(this.extraData.fileUrl);
  },
  methods: {
    async cancel() {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
.is-checked,
.is-indeterminate {
  .el-checkbox__inner {
    background-color: #81d8d0 !important;
    border-color: #81d8d0 !important;
  }
}
.is-focus,
.el-checkbox__inner:hover {
  // background-color: #81D8D0 !important;
  border-color: #81d8d0 !important;
  .el-checkbox__inner {
    border-color: #81d8d0 !important;
  }
}
.tip-view {
  margin-top: 20px;
  margin-left: 20px;
  span {
    color: #ff0101;
  }
}
.el-switch__label.is-active {
  color: #81d8d0;
}
.text-red {
  font-size: 14px;
  color: #ff0101;
}
.online-view {
  // margin-top: 10px;
  margin-left: 20px;
}
.el-form-item {
  width: 550px;
  display: inline-block;
}
.el-form-item__content {
  margin-left: 0px !important;
}
.end-date {
  .el-form-item {
    width: 160px;
  }
  .el-input,
  .el-input__inner {
    width: 160px;
  }
  .el-form-item__error {
    margin-left: 10px;
  }
}
.el-input,
.el-input__inner {
  width: 330px;
}
.el-textarea,
.el-textarea__inner {
  width: 890px;
  min-height: 80px !important;
}
.box-agree {
  .el-checkbox__inner {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  .el-checkbox__inner::after {
    height: 10px;
    left: 7px;
    top: 2px;
  }
}
.submit {
  margin-top: 10px;
  padding: 6px 20px;
}
.el-form-item__error {
  margin-left: 140px;
}
</style>
